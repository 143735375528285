import {connect} from 'react-redux';
import {type ThunkDispatch} from 'redux-thunk';
import {type Action} from 'redux';
import {useLocation, useParams} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {type FC} from 'react';

import {type AppState} from 'store/interface';
import {newExercise, resetLoading} from 'store/exercise/editor/actions/xeditor';
import {
  type XEditorDispatchProps,
  type XEditorNewUrlParams,
  type XEditorStateProps
} from 'store/exercise/editor/interface';
import {type ExerciseJSON} from 'store/exercise/player/interface';
import {toggleWizard} from 'store/exercise/editor/actions/xwizard';
import {newXExercise, resetXEditor} from 'store/exercise/editor/actions/xexercise';
import {deleteDraftExercise} from 'store/exercise/editor/actions/xdraftexercises';

import {XEditorRouteComponent} from './XEditorRoute';

interface OwnProps extends XEditorNewUrlParams {
  copiedExercise?: string;
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): XEditorStateProps => {
  const {user, xeditor} = state;
  const {unitExercise, loading, loadingError, xexercise, draftExercises} = xeditor!;
  const copiedExercise = ownProps.copiedExercise;

  return {
    user,
    unitExercise,
    loading,
    loadingError,
    xexercise,
    isNew: true,
    isCopy: Boolean(copiedExercise),
    draft: draftExercises.get('null')
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, Action>,
  ownProps: OwnProps
): XEditorDispatchProps => {
  const {copiedExercise, mainExerciseId} = ownProps;
  return {
    deleteDraft: (id: string) => dispatch(deleteDraftExercise(id)),
    setXExercise: (exercise: ExerciseJSON) => dispatch(newXExercise(exercise)),
    openWizard: () => dispatch(toggleWizard()),
    resetLoading: () => dispatch(resetLoading()),
    resetXEditor: () => dispatch(resetXEditor()),
    requestExerciseSuccess: () => ({}),
    setUnitExercise: () => ({}),
    loadExercise: (coursebookId: string, unitId: number) =>
      dispatch(newExercise(coursebookId, unitId, true, mainExerciseId, copiedExercise))
  };
};

const ConnectedRoute = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(XEditorRouteComponent));

const XEditorNewRoute: FC = () => {
  const {mainExerciseId, unitId, coursebookId} = useParams<XEditorNewUrlParams>();
  const {state} = useLocation();
  const copiedExercise = state?.copiedExercise;
  return (
    <ConnectedRoute
      mainExerciseId={mainExerciseId}
      unitId={unitId!}
      coursebookId={coursebookId!}
      copiedExercise={copiedExercise}
    />
  );
};

export default XEditorNewRoute;
